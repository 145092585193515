.container {
  margin: 32px; }

.form-control {
  margin: 6px 0; }

.error {
  color: #c00;
  display: block; }

.grommetux-text-input.grommetux-input.input-class-long {
  margin: 0 0.5em 1em 0;
  width: 100%; }

@media (max-width: 430px) {
  .grommetux-button.grommetux-button--plain.grommetux-number-input__subtract,
  .grommetux-button.grommetux-button--plain.grommetux-number-input__add {
    padding: 0.5rem !important; } }

.grommetux-text-input.grommetux-input.input-class {
  margin: 0 1em 1em 0; }

.grommetux-select,
.grommetux-select__drop {
  width: 200px; }

.grommetux-select .grommetux-button {
  padding: 0 0 !important; }

.grommetux-box.grommetux-box--direction-row.grommetux-box--justify-end.grommetux-box--flex.grommetux-box--pad-none.download-container {
  display: block; }
  .grommetux-box.grommetux-box--direction-row.grommetux-box--justify-end.grommetux-box--flex.grommetux-box--pad-none.download-container hr {
    max-width: 22em; }

.grommetux-carousel .grommetux-box--align-center {
  justify-content: center; }

.grommetux-carousel__arrow,
.grommetux-carousel__controls {
  display: none !important; }

@media (max-width: 719px) {
  .grommetux-box--direction-row.grommetux-box--responsive.button-box {
    flex-direction: column-reverse; }
    .grommetux-box--direction-row.grommetux-box--responsive.button-box .button {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px; } }

.stepper div div div div a,
.stepper div div div div span {
  line-height: 32px !important; }

@media (max-width: 430px) {
  .stepper {
    display: none; } }
